<template>
  <v-container>
    <base-card light>
      <v-row
        class="fill-height pa-3"
        align="center"
      >
        <v-col
          md="6"
          sm="12"
        >
          <v-img
            :src="require('@/assets/botellas/gintzin.png')"
            class="darken-4"
            height="550"
            width="100%"
            contain
          />
        </v-col>
        <v-col
          md="6"
          sm="12"
        >
          <p class="display-3 font-weight-light mb-5"
          v-html="content.poweredBy"
          >
          </p>
          <v-btn
            @click="onClick($event)"
            class="conoce-mas-gin"
          >
            {{ content.contactBtn }}
          </v-btn>
        </v-col>
      </v-row>
    </base-card>
  </v-container>
</template>

<script>
import {
    mapMutations
  } from 'vuex'
  export default {
    name: 'PoweredBy',
    computed: {
      content () {
        return this.$store.state.webpagecontent[0][this.$store.state.lang].page
      },
    },
     data: () => ({
      analyticsText: "contacto-gin-tzin",
      contentName: "gin-tzin"
    }),
    methods: {
      ...mapMutations(['showModal']),
      onClick (e, href) {
          e.stopPropagation()
          this.showModal()
          fbq('trackCustom', this.analyticsText, {
          content_name: this.analyticsText + ' Link',
          content_category: this.$store.state.lang,
          content_type: this.contentName,
        })
        this.$ua.trackEvent(this.analyticsText, 'Click', this.$store.state.lang + ' ' + this.contentName)
      }
    }
  }
</script>

<style scoped>
  .p-hr {
    border: 3px solid #3F5465;
    border-radius: 5px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }
  .p-title {
    margin-top: 50px;
    margin-bottom: 25px;
  }
  .conoce-mas-gin {
    color: #3F5465 !important;
    background-color: #ffa500 !important;
    border-color: #ffa500 !important;
  }
</style>
